const messages = {
    axios:{
        fskff:'로그인 상태 이상'
    },
    foot: {
        name: '회사명',
        value: '피크 네트워크 기술 주식회사 리미티드',
        address: '회사 주소',
        value1: '358 Nguyễn Văn Cừ, Phường Võ Cường, Thành phố Bắc Ninh, Tỉnh Bắc Ninh, Việt Nam',
        contact: '연락처'
      },
    tablist: {
        Home: '홈',
        Introduce: '소개',
        Story: '스토리 배경',
        Gameplay: '게임플레이 소개',
        Version: '역사적 버전',
        News: '뉴스',
        Community: '커뮤니티',
        User: '사용자 센터',
        Message: '메시지',
        Ranking: '랭킹',
        Gift: '선물 팩',
        out: '로그아웃',
        Shop: '상점',
        ACCOUNT: '계정',
        PHONE: '전화번호',
        LON: '로그인',
        Creat: '계정 만들기',
        Forgot: '비밀번호를 잊으셨나요?',
        GIFT: '선물 팩 교환',
        CONFIRM: '교환 확인',
        Enter: '계정 입력',
        Enter2: '전화번호 입력',
        password: '비밀번호 입력',
        vaapassword: '인증 코드를 입력하세요',
        adasaf:'당신의 선물 코드를 입력하세요',
        Verification:'인증 코드',
        registration: '등록',
        username: '사용자 이름',
        phone: '전화번호',
        email: '이메일',
        password2: '비밀번호',
        confirmPassword: '비밀번호 확인',
        emailVerificationCode: '이메일 인증 코드',
        changePassword: '비밀번호 변경',
        confirm: '확인',
        uyqm: '초대 코드',
        changePasswordSuccess: '비밀번호 변경 성공',
        createAccountSuccess: '계정 생성 성공'
    },
    home: {
        annnn:'안드로이드',
        apppp:'애플',
        DOWNLOAD: '다운로드',
        SING: '가입하기',
        Popular: '인기 토너먼트',
        VIEW: '전체 보기',
        collect: '최고의 게임을 플레이하고 모으세요',
        rewards: '보상',
        Aenean: 'Aenean non vulputate quan,eu dictum est. Aliquam erat',
        volupat: 'volupat. Suspendisse bibendum felis ullamcorper mauris',
        ullamcorper: 'ullamcorper',
        LATEST: '최신',
        GAME: '게임',
        TOURNAMENTS: '토너먼트',
        XBOX: '엑스박스',
        PC: 'PC',
        Search: '검색',
        BUSINESS: '우리의 비즈니스 파트너',
        SUBSCRIBE: '우리의 뉴스레터 구독하기',
        FOLLOW: '뉴스 팔로우',
        Email: '이메일 주소 입력',
    },
    New: {
        News: '뉴스',
        Announcement: '공지사항',
        Maintenance: '유지보수',
        Search: '검색',
        sdfafa:'내용이 없습니다',
    },
    Community: {
        ALL: '전체',
        GAMING: '게이밍',
        STRATEGA: '전략',
        EXPERIENCE: '경험',
        PLAYER: '플레이어',
        COMMENTS: '댓글',
        Topic: '토픽',
        Author: '작성자',
        Replies: '답글',
        Views: '조회수',
    },
    Shop: {
        PET: '애완견',
        PROPS: '소품',
        purchase: '구매',
    },
    user: {
        Personal: '개인 데이터',
        Account: '계정 설정',
        Purchase: '구매 기록',
        Ranking: '순위',
        Message: '메시지',
        Quit: '로그아웃',
        switchSlippage: '슬리피지 변경',
        slippageLimit: '슬리피지 한도',
        selectToken: '토큰 선택',
        exchange: '교환',
        dogecoin: '도지코인',
        diamond: '다이아몬드',
        price: '가격',
        connectWallet: '지갑 연결',
        minimumReceived: '최소 수령액',
        exchangePath: '교환 경로',
        withdraw: '인출',
        connectionSuccessful: '연결 성공',
        connectionFailed: '연결 실패',
        pleaseEnterPositiveInteger: '양의 정수를 입력하세요',
        invalidAmountInput: '잘못된 금액 입력',
        minimumAmountIs1U: '최소 금액은 1U입니다',
        authorizationForStakingSuccessful: '스테이킹 권한 부여 성공',
        purchaseSuccessful: '구매 성공',
        cancelled: '취소됨',
        cz: '충전',
        authFirstExchange: '첫 번째 교환은 인증이 필요하며, 다이아몬드가 1~5분 내에 도착할 것으로 예상됩니다',
        withdrawalRecords: '인출 기록',
        slippageError: '슬리피지 오류',
        slippagwweror: '이메일 및 전화를 먼저 확인하십시오',
        yyqqmm: '초대 코드',
        yitjshh: '검토를 제출했습니다',
        yitjashh: '검토 중',
        Accounts: {
            Phone: '전화번호',
            Username: '사용자 이름',
            Gender: '성별',
            City: '도시',
            Birthday: '생일',
            Profession: '직업',
            Persanal: '개인 소개',
            SAVE: '저장',
            sdaf:'이 기능은 아직 개방되지 않았습니다'
        },
        PurchaseRecord: {
            Order: '주문번호',
            Submission: '제출 시간',
            Account: '계정',
            Amount: '금액',
            Payment: '결제 방법',
            Source: '소스',
            State: '상태',
            Done: '완료',
            Fail: '실패',
            add: '주소',
            clickRetryWithdraw: '다시 인출을 클릭하세요',
            retryWithdrawDescription: '',
        },
        PersonalData: {
            emailVerification: '이메일 인증',
            phoneVerification: '전화번호 인증',
            email: '이메일',
            phoneNumber: '전화번호',
            verificationCode: '인증 코드',
            getVerificationCode: '인증 코드 받기',
            confirm: '확인',
            unverified: '미인증',
            emailFormatError: '이메일 형식 오류',
            wrongCodeOrEmail: '인증 코드 또는 이메일이 잘못되었습니다',
            bindSuccess: '바인딩 성공',
            wrongCode: '잘못된 인증 코드',
            wrongCodaaaaae: '초대 링크',

        },
        Messages: {
            ALL: '전체',
            Payment: '결제 관련',
            Problem: '문제 피드백',
        },
    },
    ContactUs: {
        Annount: '알림',
        Question: '질문 유형',
        Phone: '전화번호',
        Email: '이메일',
        Picture: '사진',
        SUBMIT: '제출',
    },
    Dog: {
        Fighting: '전투 능력',
        FGrade: 'F등급',
        Pet: '애완동물 종류',
        Life: '생명',
        Favorability: '호감도',
        Talent: '재능 유형',
        value: '재능 가치',
        Speed: '속도',
        Endurance: '지구력',
        Power: '힘',
    }
}

export default messages