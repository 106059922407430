import { createStore } from 'vuex'
export default createStore({
  state: {
    user: {//用户信息
      Phone: '',
      Username: '',
      Gender: '男',
      Birthday: '',
      City: '',
      Profession: '',
      Persanal: '',
      imgpic: '1',
      diamondNumber: 0,
      userId: 0,
      PhoneVerify: '',
      EmailVerify: '',
      email:'',
      invitationCode:''
    },
    Loginbool: false,
    pactAddress: '0x67009eb16ff64d06b4f782b3c552b924b1d1bb93',//合约地址
    ExchangeAddress: '0x8623B547D9532DA7f25179a06D01B65be1d40623',//兑换地址
    usdtAddress: '0x55d398326f99059fF775485246999027B3197955',// usdt代币合约地址
    bnbAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',// bnb代币合约地址
    ETHAddress: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',// ETH代币合约地址
    BTCAddress: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',// BTC代币合约地址
    USDCAddress: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',// USDC代币合约地址
  },
  getters: {
  },
  mutations: {
    backuser(state, i) {//修改用户信息
      state.user = i
    },
    Loguser(state, i) {//修改用户登录状态
      state.Loginbool = i
    },
    quituser(state) {//用户退出
      state.user = {
        Phone: '',
        Username: '',
        Gender: '男',
        Birthday: '',
        City: '',
        Profession: '',
        Persanal: '',
        imgpic: '',
      }
      state.Loginbool = false
      localStorage.removeItem('token');
    }
  },
  actions: {
  },
  modules: {
  }
})
