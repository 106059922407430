const messages = {
    axios:{
        fskff:'登录状态异常'
    },
    foot: {
        name: '公司名称',
        value: '派克网络科技有限公司',
        address: '公司地址',
        value1: '越南北宁省北宁市 武强坊 385号',
        contact: '联系方式',
      },
    tablist: {
        Home: '首頁',
        Introduce: '介紹',
        Story: '故事背景',
        Gameplay: '遊戲玩法介紹',
        Version: '歷史版本',
        News: '新聞',
        Community: '社區',
        User: '用戶中心',
        Message: '消息',
        Ranking: '排行榜',
        Gift: '禮包',
        Shop: '商店',
        out: '退出',
        ACCOUNT: '賬戶',
        PHONE: '電話號碼',
        LON: '登錄',
        Creat: '創建賬戶',
        Forgot: '忘記密碼？',
        GIFT: '禮包兌換',
        CONFIRM: '確認兌換',
        Enter: '輸入您的賬戶',
        Enter2: '輸入您的電話',
        password: '輸入您的密碼',
        vaapassword: '請輸入驗證碼',
        adasaf: '輸入您的禮包碼',
        Verification:'驗證碼',
        registration: '註冊',
        username: '用戶名',
        phone: '電話',
        email: '郵箱',
        password2: '密碼',
        confirmPassword: '確認密碼',
        emailVerificationCode: '郵箱驗證碼',
        changePassword: '修改密碼',
        confirm: '確認',
        uyqm: '邀請碼',
        changePasswordSuccess: '修改密碼成功',
        createAccountSuccess: '創建帳號成功'
    },
    home: {
        annnn:'安卓',
        apppp:'蘋果',
        DOWNLOAD: '下載',
        SING: '註冊',
        Popular: '熱門賽事',
        VIEW: '查看全部',
        collect: '發揮你最好的遊戲並收集',
        rewards: '獎勵',
        Aenean: 'Aenean非vulputate quan, eu dictum est. Aliquam erat',
        volupat: 'volupat。 Suspendisse bibendum felis ullamcorper mauris',
        ullamcorper: 'ullamcorper',
        LATEST: '最新',
        GAME: '遊戲',
        TOURNAMENTS: '錦標賽',
        XBOX: 'XBOX',
        PC: 'PC',
        Search: '搜索',
        BUSINESS: '我們的商業夥伴',
        SUBSCRIBE: '訂閱並接收我們的通訊',
        FOLLOW: '關注新聞',
        Email: '輸入電子郵件地址',
    },
    New: {
        News: '新聞',
        Announcement: '公告',
        Maintenance: '維護',
        Search: '搜索',
        sdfafa:'暂无内容',
    },
    Community: {
        ALL: '全部',
        GAMING: '遊戲',
        STRATEGA: '戰略',
        EXPERIENCE: '經驗',
        PLAYER: '玩家',
        COMMENTS: '評論',
        Topic: '主題',
        Author: '作者',
        Replies: '回復',
        Views: '瀏覽量',
    },
    Shop: {
        PET: '寵物狗',
        PROPS: '道具',
        purchase: '購買',
    },
    user: {
        Personal: '個人資料',
        Account: '賬戶設置',
        Purchase: '購買記錄',
        Ranking: '排行榜',
        Message: '消息',
        Quit: '退出',
        switchSlippage: '切换滑点',
        slippageLimit: '滑点上限',
        selectToken: '选择代币',
        exchange: '兑换',
        dogecoin: '狗狗币',
        diamond: '钻石',
        price: '价格',
        connectWallet: '链接钱包',
        minimumReceived: '最小获得量',
        exchangePath: '兑换路径',
        withdraw: '提现',
        connectionSuccessful: '链接成功',
        connectionFailed: '链接失败',
        pleaseEnterPositiveInteger: '请输入正整数',
        invalidAmountInput: '金额输入错误',
        minimumAmountIs1U: '金额最少为1U',
        authorizationForStakingSuccessful: '授权成功',
        purchaseSuccessful: '购买成功',
        cancelled: '已取消',
        cz: '充值',
        authFirstExchange: '第一次兌換需要先授權,預計鑽石到帳在1~5分鐘之間',
        withdrawalRecords: '提現記錄',
        slippageError: '滑點錯誤',
        slippagwweror: '請先驗證郵箱和電話',
        yyqqmm: '邀請碼',
        yitjshh: '已提交審核',
        yitjashh: '審核中',
        Accounts: {
            Phone: '電話號碼',
            Username: '用戶名',
            Gender: '性別',
            City: '城市',
            Birthday: '生日',
            Profession: '職業',
            Persanal: '個人簡介',
            SAVE: '保存',
            sdaf:'此功能未開放'
        },
        PurchaseRecord: {
            Order: '訂單號',
            Submission: '提交時間',
            Account: '賬戶',
            Amount: '金額',
            Payment: '支付方式',
            Source: '來源',
            State: '狀態',
            Done: '完成',
            Fail: '失敗',
            add: '地址',
            clickRetryWithdraw: '點擊重新提現',
            retryWithdrawDescription: ''
        },
        PersonalData: {
            emailVerification: '郵箱驗證',
            phoneVerification: '手機號驗證',
            email: '郵箱',
            phoneNumber: '手機號',
            verificationCode: '驗證碼',
            getVerificationCode: '獲取驗證碼',
            confirm: '確認',
            unverified: '未驗證',
            emailFormatError: '郵箱格式錯誤',
            wrongCodeOrEmail: '驗證碼或郵箱錯誤',
            bindSuccess: '綁定成功',
            wrongCode: '驗證碼錯誤',
            wrongCodaaaaae: '邀請链接',
        },
        Messages: {
            ALL: '全部',
            Payment: '支付相關',
            Problem: '問題反饋',
        }
    },
    ContactUs: {
        Annount: '公告',
        Question: '問題類型',
        Phone: '電話號碼',
        Email: '電子郵箱',
        Picture: '圖片',
        SUBMIT: '提交',
    },
    Dog: {
        Fighting: '戰鬥力',
        FGrade: '等級',
        Pet: '寵物類型',
        Life: '生命值',
        Favorability: '好感度',
        Talent: '天賦類型',
        value: '天賦值',
        Speed: '速度',
        Endurance: '耐力',
        Power: '力量',
    },
    whitepage:{
        wwww:'白皮书',
        title:' DOGPET 代币分配计划',
        body1:{
            title1:'引言',
            title11:'项目简介',
            title12:'总供应量',
            title13:'代币分配细节',
            child21:{
                body1: 'DOGPET 是一个基于 Binance Smart Chain (BSC) 的去中心化加密货币项目，旨在通过创新的代币经济模型和强大的社区支持，推动宠物友好型应用的发展。为了确保项目的透明度和社区的信任，我们将详细介绍DOGPET代币的分配计划。'            },
            child22:{
                body1: 'DOGPET 代币的总供应量为 21,000,000,000 DP。'
            },
            child23:{
                body1:'销毁地址 0x000000000000000000000000000000000000dEaD (Burn Address): 75%',
                body2:'分配数量: 15,750,000,000 DP',
                body3:'用途: 为了减少市场流通量，提高代币价值，我们将75%的代币发送到销毁地址。',
                body4:'基金会地址 0xB6EC9AE10a94d40a1270E2F9F136cA0804CE8462 (Foundation Address): 5%',
                body5:'分配数量: 1,050,000,000 DP',
                body6:'用途: 用于项目开发、市场推广和运营支出。',
                body7:'预售地址 0x7fCD0c72B846bE906552DceCD51C60a0396E093e (Pre-sale Address): 10%',
                body8:'分配数量: 2,100,000,000 DP',
                body9:'用途: 用于预售活动，吸引早期投资者和支持者。',
                body10:'游戏奖励池 (Game Rewards Pool): 10%',
                body11:'分配数量:  2,100,000,000 DP',
                body12:'用途: 作为玩家在游戏中通过完成任务、参与活动等方式获得的奖励代币。此部分代币将逐步释放，以保持游戏生态的稳定和长期发展。',
            }
        },
        body2:{
            title1:'多签名钱包 : 0x7EE337730EeBfbFA918e0070ebFD8595e3579d9d',
            title11:'多签名钱包管理',
            title12:'多签名钱包签署者',
            child21:{
                body1:'为了确保资金的安全和防止单点故障，未分发的代币将存放在一个多签名钱包中。多签名钱包需要多个签署者共同签名才能执行任何交易，确保代币的分配和使用透明、可靠。'
            },
            child22:{
                body1:'0xB6EC9AE10a94d40a1270E2F9F136cA0804CE8462',
                body2:'0xe509Eb736251C36d413C1ff2aF0f848A67B0b6d8',
                body3:'0x66d6C29D159bAE098a96a85A8A99da93d6ef5BfE',
                body4:'0x0A210631DD7f2EA689b2c38C4D33D21f55C86c9c',
                body5:'0x8E20735d0a7958183673c53e3412D4b4c5011942'
            },
            child23:{
                body1:'',
            },
        },
        body3:{
            title1:'计划',
            title11:'锁仓和释放计划',
            title12:'基金会代币锁仓计划',
            title13:'游戏奖励代币释放计划',
            child21:{
                body1:'为了保障项目的长期发展和稳定，我们将对部分代币进行锁仓，并设定释放时间表。'
            },
            child22:{
                body1:'50% 的基金会代币将在发布后锁仓 6 个月，之后按月线性释放。'
            },
            child23:{
                body1:'游戏奖励代币将根据游戏活动和玩家参与度逐步释放，以确保游戏生态的长期稳定和健康发展。',
            },
        },
        body4:{
            title1:'透明度与监督',
            title11:'为了确保代币分配的透明性和可监督性，我们将定期在官网上发布代币分配和使用情况报告，欢迎社区成员和利益相关者的监督和反馈',
        }

    }
}

export default messages