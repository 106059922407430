const messages = {
    axios:{
        fskff:'Abnormal login status'
    },
    foot: {
        name: 'Company Name',
        value: 'PEAK NETWORK TECHNOLOGY INC COMPANY LIMITED ',
        address: 'Company Address',
        value1: '358 Nguyễn Văn Cừ, Phường Võ Cường, Thành phố Bắc Ninh, Tỉnh Bắc Ninh, Việt Nam',
        contact: 'Contact Information'
      },
    tablist:{
        Home:'Home',
        Introduce:'Introduce',
        Story:'Story Background',
        Gameplay:'Gameplay Introduction',
        Version:'Historical Version',
        News:'News',
        Community:'Community',
        User:'User Center',
        Message:'Message',
        Ranking:'Ranking',
        Gift:'Gift Pack',
        out:'out',
        Shop:'Shop',
        ACCOUNT:'ACCOUNT',
        PHONE:'PHONE NUMBER',
        LON:'LON IN',
        Creat:'Creat an account',
        Forgot:'Forgot Password?',
        GIFT:'GIFT PACK REDEMPTION',
        CONFIRM:'CONFIRM EXCHANGE',
        Enter:'Enter your account',
        Enter2: 'Enter your phone number',
        password:'Enter your password ',
        vaapassword: 'Please enter the verification code',
        adasaf:'Enter your gift code',
        Verification:'Verification Code',
        registration: 'Register',
        username: 'Username',
        phone: 'Phone',
        email: 'Email',
        password2: 'Password',
        confirmPassword: 'Confirm Password',
        emailVerificationCode: 'Email Verification Code',
        changePassword: 'Change Password',
        confirm: 'Confirm',
        uyqm: 'Invitation Code',
        changePasswordSuccess: 'Change Password Success',
        createAccountSuccess: 'Create Account Success'
    },
    home:{
        annnn:'Android',
        apppp:'Apple',
        DOWNLOAD:'DOWNLOAD',
        SING:'SING UP',
        Popular:'Popular Tournaments',
        VIEW:'VIEW ALL',
        collect:'play your best game and collect',
        rewards:'rewards',
        Aenean:'Aenean non vulputate quan,eu dictum est. Aliquam erat',
        volupat:'volupat. Suspendisse bibendum felis ullamcorper mauris',
        ullamcorper:'ullamcorper',
        LATEST:'LATEST',
        GAME:'GAME',
        TOURNAMENTS:'TOURNAMENTS',
        XBOX:'XBOX',
        PC:'PC',
        Search:'Search',
        BUSINESS:'OUR BUSINESS PARTNER',
        SUBSCRIBE:'SUBSCRIBE AND RECEIVE OUR NEWSLETTER',
        FOLLOW:'TO FOLLOW THE NEWS',
        Email:'Enter Email Adress',
    },
    New:{
        News:'News',
        Announcement:'Announcement',
        Maintenance:'Maintenance',
        Search:'Search',
        sdfafa:'No content available',
    },
    Community:{
        ALL:'ALL',
        GAMING:'GAMING',
        STRATEGA:'STRATEGA',
        EXPERIENCE:'EXPERIENCE',
        PLAYER:'PLAYER',
        COMMENTS:'COMMENTS',
        Topic:'Topic',
        Author:'Author',
        Replies:'Replies',
        Views:'Views',
    },
    Shop:{
        PET:'PET DOGS',
        PROPS:'PROPS',
        purchase:'PURCHASE',
    },
    user:{
        Personal:'Personal Data',
        Account:'Account Settings',
        Purchase:'Purchase Record',
        Ranking:'Ranking',
        Message:'Message',
        Quit:'Quit',
        switchSlippage: 'Switch Slippage',
        slippageLimit: 'Slippage Limit',
        selectToken: 'Select Token',
        exchange: 'Exchange',
        dogecoin: 'Dogecoin',
        diamond: 'Diamond',
        price: 'Price',
        connectWallet: 'Connect Wallet',
        minimumReceived: 'Minimum Received',
        exchangePath: 'Exchange Path',
        withdraw: 'Withdraw',
        connectionSuccessful: 'Connection Successful',
        connectionFailed: 'Connection Failed',
        pleaseEnterPositiveInteger: 'Please Enter a Positive Integer',
        invalidAmountInput: 'Invalid Amount Input',
        minimumAmountIs1U: 'Minimum Amount is 1U',
        authorizationForStakingSuccessful: 'Authorization for Staking Successful',
        purchaseSuccessful: 'Purchase Successful',
        cancelled: 'Cancelled',
        cz: 'Recharge',
        authFirstExchange: 'First exchange requires authorization, expect diamond to arrive within 1~5 minutes',
        withdrawalRecords: 'Withdrawal Records',
        slippageError: 'Slippage Error',
        slippagwweror: 'Please verify your email and phone first',
        yyqqmm: 'Invitation Code',
        yitjshh: 'Submitted for review',
        yitjashh: 'Under review',
        Accounts:{
            Phone:'Phone Number',
            Username:'Username',
            Gender:'Gender',
            City:'City',
            Birthday:'Birthday',
            Profession:'Profession',
            Persanal:'Persanal Intraduction',
            SAVE:'SAVE',
            sdaf:'This feature is not yet available'
        },
        PurchaseRecord:{
            Order:'Order Number',
            Submission:'Submission Time',
            Account:'Account',
            Amount:'Amount',
            Payment:'Payment Method',
            Source:'Source',
            State:'State',
            Done:'Done',
            Fail:'Fail',
            add: 'Address',
            clickRetryWithdraw: 'Click to retry withdrawal',
        retryWithdrawDescription: '',
        },
        PersonalData: {
            emailVerification: 'Email Verification',
            phoneVerification: 'Phone Verification',
            email: 'Email',
            phoneNumber: 'Phone Number',
            verificationCode: 'Verification Code',
            getVerificationCode: 'Get Verification Code',
            confirm: 'Confirm',
            unverified: 'Unverified',
            emailFormatError: 'Invalid Email Format',
            wrongCodeOrEmail: 'Incorrect Verification Code or Email',
            bindSuccess: 'Binding Successful',
            wrongCode: 'Incorrect Verification Code',
            wrongCodaaaaae: 'Invitation Link',
        },
        Messages:{
            ALL:'ALL',
            Payment:'Payment Related',
            Problem:'Problem Peedback',
        },
    },
    ContactUs:{
        Annount:'Annount',
        Question:'Question type ',
        Phone:'Phone number ',
        Email:'E-mail',
        Picture:'Picture',
        SUBMIT:'SUBMIT',
    },
    Dog:{
        Fighting:'Fighting capacity',
        FGrade:'FGrade',
        Pet:'Pet type',
        Life:'Life',
        Favorability:'Favorability',
        Talent:'Talent type',
        value:'Talent value',
        Speed:'Speed',
        Endurance:'Endurance',
        Power:'Power',
    },
    whitepage:{
        wwww:'Whitepaper',
        title:'DOGPET Token Distribution Plan',
        body1:{
            title1:'Introduction',
            title11:'Project Overview',
            title12:'Total Supply',
            title13:'Token Distribution Details',
            child21:{
                body1: 'DOGPET is a decentralized cryptocurrency project based on the Binance Smart Chain (BSC), aimed at promoting pet-friendly applications through innovative tokenomics and strong community support. To ensure transparency and community trust, we outline the DOGPET token distribution plan in detail.'
            },
            child22:{
                body1: 'The total supply of DOGPET tokens is 21,000,000,000 DP.'
            },
            child23:{
                body1:'Burn Address 0x000000000000000000000000000000000000dEaD : 75%',
                body2:'Allocation: 15,750,000,000 DP',
                body3:'Purpose: To reduce market circulation and enhance token value, 75% of the tokens will be sent to the burn address.',
                body4:'Foundation Address  0xB6EC9AE10a94d40a1270E2F9F136cA0804CE8462 : 5%',
                body5:'Allocation: 1,050,000,000 DP',
                body6:'Purpose: For project development, marketing, and operational expenses.',
                body7:'Pre-sale Address 0x7fCD0c72B846bE906552DceCD51C60a0396E093e : 10%',
                body8:'Allocation: 2,100,000,000 DP',
                body9:'Purpose: Used for pre-sale activities to attract early investors and supporters.',
                body10:'Game Rewards Pool: 10%',
                body11:'Allocation: 2,100,000,000 DP',
                body12:'Purpose: Tokens awarded to players for tasks completed and participation in activities within the game. This portion of tokens will be gradually released to maintain stability and long-term development of the gaming ecosystem.',
            }
        },
        body2:{
            title1:'Multi-Signature Wallet : 0x7EE337730EeBfbFA918e0070ebFD8595e3579d9d',
            title11:'Multi-Signature Wallet Management',
            title12:'Multi-Signature Wallet Signatories',
            title13:'Multi-Signature Wallet Address',
            child21:{
                body1:'To ensure fund security and prevent single-point failure, undistributed tokens will be stored in a multi-signature wallet. This wallet requires multiple signatories to authorize any transactions, ensuring transparent and reliable token allocation and usage.'
            },
            child22:{
                body1:'0xB6EC9AE10a94d40a1270E2F9F136cA0804CE8462',
                body2:'0xe509Eb736251C36d413C1ff2aF0f848A67B0b6d8',
                body3:'0x66d6C29D159bAE098a96a85A8A99da93d6ef5BfE',
                body4:'0x0A210631DD7f2EA689b2c38C4D33D21f55C86c9c',
                body5:'0x8E20735d0a7958183673c53e3412D4b4c5011942'
            },
            child23:{
                body1:'',
            },
        },
        body3:{
            title1:'Plans',
            title11:'Lock-up and Release Plans',
            title12:'Foundation Token Lock-up Plan',
            title13:'Game Rewards Token Release Plan',
            child21:{
                body1:'To ensure long-term development and stability of the project, we will lock up a portion of the tokens and establish a release schedule.'
            },
            child22:{
                body1:'50% of the Foundation tokens will be locked up for 6 months post-launch, followed by linear monthly releases.'
            },
            child23:{
                body1:'Game rewards tokens will be gradually released based on game activities and player engagement, ensuring long-term stability and healthy development of the gaming ecosystem.',
            },
        },
        body4:{
            title1:'Transparency and Oversight',
            title11:'To ensure transparency and oversight of token distribution, we will regularly publish reports on token allocation and usage on our official website, welcoming supervision and feedback from community members and stakeholders.',
        }

    }


}

export default messages