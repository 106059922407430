<template>
  <router-view />
</template>
<script setup>
import { useStore } from "vuex"
import { onMounted } from 'vue'
import axios from '@/axios';
const store = useStore();
let Login = (i) => {//修改登录状态
  store.commit('Loguser', i)
}
onMounted(() => {

  if (localStorage.getItem('token')) {
    Login(true)
    lisadd(localStorage.getItem('token'))
  }
})
async function lisadd(i) {
  let data = {
    data: {
      body: {
        token: i
      },
      signature: "MEYCIQC8oH6BUdXZxMi0/ScBDvSCCTQdWUYvVb1THNyr8AkXZQIhAMj7/gLleTrW+IHI9oe7mFnWW/vOnP9bxqNMhy6gym34"
    }
  }
  const response = await axios.post("/userProperties/UserProperties/findOne", data);
  // const response2 = await axios.post("/users/getCaptchaEmail", data);
  console.log(response);
  let user = {
    Phone: response.body.userPhone,
    Username: response.body.userName,
    Gender: '男',
    Birthday: '',
    City: '',
    Profession: '',
    Persanal: '',
    imgpic: response.body.headIcon,
    diamondNumber: response.body.diamondNumber,
    userId: response.body.userId,
    PhoneVerify: response.body.PhoneVerify,
    EmailVerify: response.body.EmailVerify,
    email: response.body.email,
    invitationCode: response.body.invitationCode,
    }
  store.commit('backuser', user)
}
</script>
<style lang="scss">
.header-new-drop {
  background-image: url('@/assets/home/awwww.png');
  background-size: 120% 120%;
  background-repeat: no-repeat;
  /* 防止背景图像重复 */
  background-position: center center;
  /* 背景图像居中定位 */

  li {
    width: 100px;
    color: rgb(255, 255, 255);
    font-weight: bold;
  }
}

.header-new-drop2 {
  background-image: url('@/assets/home/awwww.png');
  background-size: 120% 120%;
  background-repeat: no-repeat;
  background-position: center center;
  background-position: center center;
  border: none;

  li {
    color: rgb(255, 255, 255);
    font-weight: bold;
  }
}
</style>
