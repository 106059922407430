import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/Home',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
    children: [
      {
        path: "/HomePage/HomePage",
        component: () => import("@/views/HomePage/HomePage.vue"),
        name: 'HomePage'
      },
      {
        path: "/HomePage/IntroducePage",
        component: () => import("@/views/HomePage/IntroducePage.vue"),
        name: 'IntroducePage',
        children: [
          {
            path: "/HomePage/IntroducePage/StoryBackground",
            component: () => import("@/views/HomePage/StoryBackground.vue"),
            name: 'StoryBackground'
          },
          {
            path: "/HomePage/IntroducePage/GameplayIntroduction",
            component: () => import("@/views/HomePage/GameplayIntroduction.vue"),
            name: 'GameplayIntroduction'
          },
          {
            path: "/HomePage/IntroducePage/HistoricalVersion",
            component: () => import("@/views/HomePage/HistoricalVersion.vue"),
            name: 'HistoricalVersion'
          },
          {
            path: "/HomePage/IntroducePage",
            redirect: "/HomePage/IntroducePage/StoryBackground"
          },
        ]
      },
      {
        path: "/HomePage/NewsPage",
        component: () => import("@/views/HomePage/NewPage.vue"),
        name: 'NewPage',
      },
      {
        path: "/HomePage/CommunityPage",
        component: () => import("@/views/HomePage/CommunityPage.vue"),
        name: 'CommunityPage'
      },
      {
        path: "/HomePage/InNews",
        component: () => import("@/views/NewsPage/InNews.vue"),
        name: 'InNews'
      },
      {
        path: "/HomePage/ShopPage",
        component: () => import("@/views/HomePage/ShopPage.vue"),
        name: 'ShopPage'
      },
      {
        path: "/HomePage/EventsPage",
        component: () => import("@/views/HomePage/EventsPage.vue"),
        name: 'EventsPage'
      },
      {
        path: "/HomePage/UserPage",
        component: () => import("@/views/UserPage/UserCenter.vue"),
        name: 'UserPage',
        children: [
          {
            path: "/HomePage/UserPage/AccountSettings",
            component: () => import("@/views/UserPage/AccountSettings.vue"),
            name: 'AccountSettings'
          },
          {
            path: "/HomePage/UserPage/PersonalData",
            component: () => import("@/views/UserPage/PersonalData.vue"),
            name: 'PersonalData'
          },
          {
            path: "/HomePage/UserPage/PurchaseRecord",
            component: () => import("@/views/UserPage/PurchaseRecord.vue"),
            name: 'PurchaseRecord'
          },
          {
            path: "/HomePage/UserPage/RankingPage",
            component: () => import("@/views/UserPage/RankingPage.vue"),
            name: 'RankingPage'
          },
          {
            path: "/HomePage/UserPage/MessagePage",
            component: () => import("@/views/UserPage/MessagePage.vue"),
            name: 'MessagePage'
          },
          {
            path: "/HomePage/UserPage/WithdrawPage",
            component: () => import("@/views/UserPage/WithdrawPage.vue"),
            name: 'WithdrawPage'
          },
          {
            path: "/HomePage/UserPage",
            redirect: "/HomePage/UserPage/PersonalData"
          },
        ]
      },
      {
        path: "/HomePage/PrivacyPolicy",
        component: () => import("@/views/HomePage/PrivacyPolicy.vue"),
        name: 'PrivacyPolicy'
      },
      {
        path: "/HomePage/ContactUs",
        component: () => import("@/views/HomePage/ContactUs.vue"),
        name: 'ContactUs'
      },
      {
        path: "/HomePage/Whitepaper",
        component: () => import("@/views/HomePage/WhitepaperPage.vue"),
        name: 'WhitepaperPage'
      },
      {
        path: "/HomePage/DogPage",
        component: () => import("@/views/HomePage/DogPage.vue"),
        name: 'DogPage'
      },
      {
        path: "/Home",
        redirect: "/HomePage/HomePage"
      },
    ],
  },
  {
    path: "/",
    redirect: "/Home"
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { top: 0 }
  }
})

export default router
