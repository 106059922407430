const messages = {
    axios: {
        fskff: 'สถานะการเข้าสู่ระบบผิดปกติ'
    },
    foot: {
        name: 'ชื่อ บริษัท',
        value: 'บริษัท พีค เน็ทเวิร์ค เทคโนโลยี อินคอร์โปรเรชั่น จำกัด',
        address: 'ที่อยู่ บริษัท',
        value1: '358 Nguyễn Văn Cừ, Phường Võ Cường, Thành phố Bắc Ninh, Tỉnh Bắc Ninh, Việt Nam',
        contact: 'ข้อมูลการติดต่อ'
      },
    tablist: {
        Home: 'หน้าหลัก',
        Introduce: 'แนะนำ',
        Story: 'พื้นหลังเรื่องราว',
        Gameplay: 'แนะนำการเล่นเกม',
        Version: 'รุ่นประวัติศาสตร์',
        News: 'ข่าวสาร',
        Community: 'ชุมชน',
        User: 'ศูนย์ผู้ใช้',
        Message: 'ข้อความ',
        Ranking: 'ตารางคะแนน',
        Gift: 'แพ็คของขวัญ',
        Shop: 'ร้านค้า',
        out: 'ออกจากระบบ',
        ACCOUNT: 'บัญชีผู้ใช้',
        PHONE: 'หมายเลขโทรศัพท์',
        LON: 'เข้าสู่ระบบ',
        Creat: 'สร้างบัญชี',
        Forgot: 'ลืมรหัสผ่าน?',
        GIFT: 'แลกของขวัญ',
        CONFIRM: 'ยืนยันการแลกเปลี่ยน',
        Enter: 'ป้อนบัญชีของคุณ',
        Enter2: 'ป้อนโทรศัพท์ของคุณ',
        password: 'ป้อนรหัสผ่านของคุณ',
        vaapassword: 'กรุณาใส่รหัสยืนยัน',
        adasaf: 'ป้อนรหัสของขวัญของคุณ',
        Verification: 'รหัสยืนยัน',
        registration: 'สมัครสมาชิก',
        username: 'ชื่อผู้ใช้',
        phone: 'โทรศัพท์',
        email: 'อีเมล',
        password2: 'รหัสผ่าน',
        confirmPassword: 'ยืนยันรหัสผ่าน',
        emailVerificationCode: 'รหัสยืนยันทางอีเมล',
        changePassword: 'เปลี่ยนรหัสผ่าน',
        confirm: 'ยืนยัน',
        uyqm: 'รหัสเชิญ',
        changePasswordSuccess: 'เปลี่ยนรหัสผ่านสำเร็จ',
        createAccountSuccess: 'สร้างบัญชีสำเร็จ'
    },
    home: {
        annnn: 'แอนดรอยด์',
        apppp: 'แอปเปิ้ล',
        DOWNLOAD: 'ดาวน์โหลด',
        SING: 'สมัครสมาชิก',
        Popular: 'ทัวร์นาเมนต์ยอดนิยม',
        VIEW: 'ดูทั้งหมด',
        collect: 'เล่นเกมของคุณให้ดีที่สุดและสะสม',
        rewards: 'รางวัล',
        Aenean: 'Aenean ไม่ใช่ vulputate quan, eu dictum est. Aliquam erat',
        volupat: 'volupat. Suspendisse bibendum felis ullamcorper mauris',
        ullamcorper: 'ullamcorper',
        LATEST: 'ล่าสุด',
        GAME: 'เกม',
        TOURNAMENTS: 'ทัวร์นาเมนต์',
        XBOX: 'XBOX',
        PC: 'พีซี',
        Search: 'ค้นหา',
        BUSINESS: 'พาร์ทเนอร์ทางธุรกิจของเรา',
        SUBSCRIBE: 'สมัครรับจดหมายข่าวของเรา',
        FOLLOW: 'ติดตามข่าวสาร',
        Email: 'ป้อนที่อยู่อีเมล',
    },
    New: {
        News: 'ข่าวสาร',
        Announcement: 'ประกาศ',
        Maintenance: 'การบำรุงรักษา',
        Search: 'ค้นหา',
        sdfafa: 'ไม่มีเนื้อหา',
    },
    Community: {
        ALL: 'ทั้งหมด',
        GAMING: 'เกมมิ่ง',
        STRATEGA: 'สตราเทกา',
        EXPERIENCE: 'ประสบการณ์',
        PLAYER: 'ผู้เล่น',
        COMMENTS: 'ความคิดเห็น',
        Topic: 'หัวข้อ',
        Author: 'ผู้เขียน',
        Replies: 'ตอบกลับ',
        Views: 'มุมมอง',
    },
    Shop: {
        PET: 'สุนัขเลี้ยง',
        PROPS: 'อุปกรณ์',
        purchase: 'ซื้อ',
    },
    user: {
        Personal: 'ข้อมูลส่วนบุคคล',
        Account: 'การตั้งค่าบัญชี',
        Purchase: 'บันทึกการซื้อ',
        Ranking: 'อันดับ',
        Message: 'ข้อความ',
        Quit: 'ออกจากระบบ',
        switchSlippage: 'สลับสลิปเปจ',
        slippageLimit: 'ความอ่อนแอของสลิปเปจ',
        selectToken: 'เลือกโทเค็น',
        exchange: 'แลกเปลี่ยน',
        dogecoin: 'ดอจคอยน์',
        diamond: 'เพชร',
        price: 'ราคา',
        connectWallet: 'เชื่อมต่อกระเป๋าสตางค์',
        minimumReceived: 'ขั้นต่ำที่จะได้รับ',
        exchangePath: 'เส้นทางการแลกเปลี่ยน',
        withdraw: 'ถอน',
        connectionSuccessful: 'เชื่อมต่อสำเร็จ',
        connectionFailed: 'การเชื่อมต่อล้มเหลว',
        pleaseEnterPositiveInteger: 'โปรดป้อนจำนวนเต็มบวก',
        invalidAmountInput: 'ข้อมูลจำนวนไม่ถูกต้อง',
        minimumAmountIs1U: 'จำนวนขั้นต่ำคือ 1U',
        authorizationForStakingSuccessful: 'การอนุญาตให้มีสิทธิ์จำนวนเงินฝากเรียบร้อยแล้ว',
        purchaseSuccessful: 'การซื้อสำเร็จ',
        cancelled: 'ยกเลิกแล้ว',
        cz: 'เติมเงิน',
        authFirstExchange: 'การแลกเปลี่ยนครั้งแรกต้องมีการอนุญาต, คาดว่าเพชรจะมาถึงใน 1~5 นาที',
        withdrawalRecords: 'บันทึกการถอน',
        slippageError: 'ข้อผิดพลาดของการสลิปเปจ',
        slippagwweror: 'โปรดยืนยันอีเมลและโทรศัพท์ของคุณก่อน',
        yyqqmm: 'รหัสเชิญ',
        yitjshh: 'ได้ส่งเพื่อการตรวจสอบแล้ว',
        yitjashh: 'อยู่ระหว่างการตรวจสอบ',
        Accounts: {
            Phone: 'หมายเลขโทรศัพท์',
            Username: 'ชื่อผู้ใช้',
            Gender: 'เพศ',
            City: 'เมือง',
            Birthday: 'วันเกิด',
            Profession: 'อาชีพ',
            Persanal: 'บทนำส่วนบุคคล',
            SAVE: 'บันทึก',
            sdaf: 'ฟีเจอร์นี้ยังไม่เปิดให้บริการ'
        },
        PurchaseRecord: {
            Order: 'หมายเลขคำสั่งซื้อ',
            Submission: 'เวลาส่ง',
            Account: 'บัญชี',
            Amount: 'จำนวน',
            Payment: 'วิธีการชำระเงิน',
            Source: 'แหล่งที่มา',
            State: 'สถานะ',
            Done: 'เสร็จสมบูรณ์',
            Fail: 'ล้มเหลว',
            add: 'ที่อยู่',
            clickRetryWithdraw: 'คลิกเพื่อลองถอนใหม่',
            retryWithdrawDescription: ''
        },
        PersonalData: {
            emailVerification: 'การตรวจสอบอีเมล',
            phoneVerification: 'การตรวจสอบโทรศัพท์',
            email: 'อีเมล',
            phoneNumber: 'หมายเลขโทรศัพท์',
            verificationCode: 'รหัสยืนยัน',
            getVerificationCode: 'รับรหัสยืนยัน',
            confirm: 'ยืนยัน',
            unverified: 'ยังไม่ได้รับการยืนยัน',
            emailFormatError: 'รูปแบบอีเมลไม่ถูกต้อง',
            wrongCodeOrEmail: 'รหัสยืนยันหรืออีเมลไม่ถูกต้อง',
            bindSuccess: 'ผูกสำเร็จ',
            wrongCode: 'รหัสยืนยันไม่ถูกต้อง',
            wrongCodaaaaae: 'ลิงก์เชิญ',
        },
        Messages: {
            ALL: 'ทั้งหมด',
            Payment: 'เกี่ยวกับการชำระเงิน',
            Problem: 'ปัญหาที่พบ',
        },
    },
    ContactUs: {
        Annount: 'ประกาศ',
        Question: 'ประเภทคำถาม',
        Phone: 'หมายเลขโทรศัพท์',
        Email: 'อีเมล',
        Picture: 'ภาพ',
        SUBMIT: 'ส่ง',
    },
    Dog: {
        Fighting: 'ความสามารถในการต่อสู้',
        FGrade: 'เกรด F',
        Pet: 'ประเภทสัตว์เลี้ยง',
        Life: 'ชีวิต',
        Favorability: 'ความนิยม',
        Talent: 'ประเภทความสามารถพิเศษ',
        value: 'ค่าความสามารถพิเศษ',
        Speed: 'ความเร็ว',
        Endurance: 'ความอึด',
        Power: 'พลัง',
    }
}

export default messages